import React from "react";

function Footer() {
  return (
    <div className="footer-container row-c-fe">
      <p>Template by Kevin Yu</p>
    </div>
  );
}

export default Footer;
